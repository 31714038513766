<template>
  <VContainer
    fluid
  >
    <DialogWrapper
      v-model="dialog"
      fullscreen
      @click:outside="closeModal('dialog')"
    >
      <LocationForm
        v-if="dialog"
        :edit="edit"
        :edit-entity="activeEntity"
        @save="handleSave"
        @close="closeModal('dialog')"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="deleteDialog"
      width="396"
      content-class="tt-card"
      hide-overlay
      @click:outside="deleteDialog = false"
    >
      <DeleteForm
        v-if="toDeleteId"
        @delete="handleDelete"
        @cancel="deleteDialog = false"
      >
        <h3 class="delete-form__title">
          Удалить локацию?
        </h3>
        <p
          v-if="globalErrorMessage"
          class="error--text mt-2"
        >
          {{ globalErrorMessage }}
        </p>
        <p class="mb-0">
          Вся информация и оргструктуры для локации «{{ selectedLocationForDelete.name }}»,
          включая контент приложения, сотрудников, встречи и шаблоны для них,
          обращения, события и аналитику по ним, отчеты, будут удален.
        </p>
      </DeleteForm>
    </DialogWrapper>
    <VRow>
      <VCol cols="6">
        <h1 class="tt-text-headline-1">
          Локации
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="6">
        <TTTextField
          v-model="search"
          clear-icon="fas fa-times-circle"
          append-icon="fal fa-search"
          large
          label="Поиск"
          placeholder="Поиск локации"
          clearable
          data-test="location-view-search"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTDataTable
          :items="locations"
          :headers="headers"
          hide-default-footer
          :items-per-page="-1"
          data-test="location-table"
        >
          <template #item="{item}">
            <tr :data-test="`location-table-row-${item.id}`">
              <td>
                <div data-test="location-table-name">
                  {{ item.name }}
                  <VIcon
                    v-if="item.is_active"
                    color="success"
                    size="16"
                    class="mx-1"
                  >
                    fas fa-check-circle
                  </VIcon>
                  <VIcon
                    v-else
                    color="tt-light-mono-24"
                    size="16"
                    class="mx-1"
                  >
                    fas fa-stop-circle
                  </VIcon>
                </div>
                <div
                  v-if="item.auto_login_url"
                  class="tt-black--text text--lighten-2"
                  data-test="location-table-login-url"
                >
                  {{ item.auto_login_url }}
                </div>
              </td>
              <td style="overflow-wrap: anywhere;">
                <div data-test="location-table-address">
                  {{ item.address || '' }}
                </div>
              </td>
              <td style="overflow-wrap: anywhere;">
                <div data-test="location-table-city">
                  {{ item.city ? item.city.name:'' }}
                </div>
              </td>
              <td>
                <div
                  class="d-flex align-center"
                  style="min-width: 160px;"
                >
                  <div data-test="location-table-language">
                    {{ languages[item.language] || '' }}
                  </div>
                  <VSpacer />
                  <div>
                    <VMenu
                      content-class="v-menu-shadow"
                      offset-y
                      left
                      min-width="250px"
                      nudge-right="16px"
                    >
                      <template #activator="{ on }">
                        <TTBtn
                          fab
                          small
                          depressed
                          color="transparent tt-ghost--text"
                          :ripple="false"
                          class="table-menu-button"
                          data-test="location-table-menu"
                          v-on="on"
                        >
                          <VIcon size="19">
                            fal fa-ellipsis-h
                          </VIcon>
                        </TTBtn>
                      </template>
                      <VCard class="v-menu-card">
                        <VList dense>
                          <VListItem
                            class="custom-menu-item"
                            :data-test="`button-edit-${item.id}`"
                            @click="openModal('dialog',item)"
                          >
                            <VListItemIcon>
                              <VIcon
                                size="19"
                                color="tt-black"
                              >
                                fal fa-edit
                              </VIcon>
                            </VListItemIcon>
                            <VListItemContent>Редактировать</VListItemContent>
                          </VListItem>
                          <VListItem
                            class="custom-menu-item"
                            :data-test="`button-delete-${item.id}`"
                            @click="openDelete(item)"
                          >
                            <VListItemIcon>
                              <VIcon
                                size="19"
                                color="error"
                              >
                                fal fa-trash-alt
                              </VIcon>
                            </VListItemIcon>
                            <VListItemContent class="error--text">
                              Удалить
                            </VListItemContent>
                          </VListItem>
                        </VList>
                      </VCard>
                    </VMenu>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import debounce from 'lodash/debounce';
import LocationForm from '@/components/forms/LocationForm.vue';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import { mapState } from 'vuex';

export default {
  name: 'Location',
  components: { DialogWrapper, DeleteForm, LocationForm },
  data() {
    return {
      globalErrorMessage: '',
      dialog: false,
      deleteDialog: false,
      search: '',
      locations: [],
      toDeleteId: 0,
      edit: false,
      activeEntity: {},
      error: { message: '' },
      headers: [
        {
          text: 'Название',
          value: 'name',
          sortable: false,
          width: '43%',
        },
        {
          text: 'Адрес',
          value: 'address',
          sortable: false,
          width: '25%',
        },
        {
          text: 'Город',
          value: 'city',
          sortable: false,
        },
        {
          text: 'Язык',
          value: 'language',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState('customerInfo', ['languages']),
    selectedLocationForDelete() {
      return this.locations.find((loc) => loc.id === this.toDeleteId);
    },
  },
  watch: {
    search() {
      this.handleSearch();
    },
  },
  created() {
    this.handleSearch = debounce(this.draftSearch, 1000);
    this.getData();
  },
  mounted() {
    this.$root.$on('showLocationForm', this.createLocation);
  },
  beforeDestroy() {
    this.$root.$off('showLocationForm', this.createLocation);
  },
  methods: {
    createLocation() {
      this.openModal('dialog');
    },
    handleDelete() {
      this.$repositories.location.delete(this.toDeleteId).then(() => {
        this.deleteDialog = false;
        this.toDeleteId = 0;
        return this.getData();
      }).then((r) => {
        const { data } = r.data;
        this.locations = data;
      }).catch((e) => {
        console.warn(e);
        const { response } = e;
        // Глобальные ошибки
        if (response.data.error.data || response.data.error) {
          this.globalErrorMessage = response.data.error.message || '';
        }
      });
    },
    openDelete(item) {
      this.globalErrorMessage = '';
      this.deleteDialog = true;
      this.toDeleteId = item.id;
    },
    handleSave() {
      this.closeModal('dialog');
      this.getData();
    },
    openModal(name, item) {
      this[name] = true;
      if (item) {
        this.activeEntity = item;
        this.edit = true;
      }
    },
    closeModal(name) {
      this[name] = false;
      this.edit = false;
      this.activeEntity = {};
    },
    draftSearch() {
      this.getData();
    },
    getData() {
      return this.$repositories.location.list({ filter: { name: this.search } }).then((r) => {
        const { data } = r.data;
        this.locations = data || [];
      }).catch((e) => {
        console.warn(e);
        this.locations = [];
      });
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
