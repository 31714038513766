<template>
  <div>
    <slot />
    <div class="d-flex justify-end mt-3">
      <TTBtn
        large
        color="tt-primary"
        text
        class="mr-3"
        depressed
        data-test="button-cancel"
        @click="$emit('cancel')"
      >
        Отменить
      </TTBtn>
      <TTBtn
        large
        color="tt-primary"
        depressed
        data-test="button-submit"
        @click="$emit('submit')"
      >
        {{ submitText }}
      </TTBtn>
    </div>
    <TTBtn
      absolute
      top
      right
      width="20"
      height="20"
      min-width="20"
      class="pa-0"
      depressed
      color="transparent"
      @click="$emit('cancel')"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-times
      </VIcon>
    </TTBtn>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationForm',
  props: {
    submitText: {
      type: String,
      default: ' Отправить',
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
