<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <DialogWrapper
      v-model="autoLoginDialog"
      width="396"
      content-class="tt-card"
      @click:outside="autoLoginDialog = false"
    >
      <DeleteForm
        @delete="autoLoginDialog = false"
        @cancel="handleCancelDialog"
      >
        <h3>Вы уверены, что хотите<br>запретить анонимный<br>доступ?</h3>
        <p class="mt-3 mb-0">
          После запрета анонимного доступа,
          восстановить<br>доступ по старой ссылке уже будет нельзя
        </p>
      </DeleteForm>
    </DialogWrapper>

    <DialogWrapper
      v-model="publishDialog"
      width="396"
      content-class="tt-card"
      @click:outside="publishDialog = false"
    >
      <ConfirmationForm
        submit-text="Опубликовать"
        data-test="location-form-dialog-publish"
        @submit="save(true)"
        @cancel="publishDialog = false"
      >
        <h3>
          Сотрудники, находящиеся в тестовой локации будут удалены
        </h3>
      </ConfirmationForm>
    </DialogWrapper>
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <h1
              class="tt-text-headline-1 mb-6"
              data-test="title-form"
            >
              {{ edit ? 'Редактировать' : 'Создать' }} локацию
            </h1>
            <div
              v-if="globalErrorMessage"
              class="error--text mt-2"
            >
              {{ globalErrorMessage }}
            </div>
            <VRow v-if="isShowAlert">
              <VCol cols="12">
                <TTAlert
                  class="alert tt-light-yellow pale elevation-0 rounded-lg"
                  width="auto"
                  height="auto"
                >
                  <template #prepend>
                    <VIcon
                      class="mr-2"
                      color="warning"
                    >
                      $warningAlert
                    </VIcon>
                  </template>
                  <template #default>
                    <div class="tt-text-body-2">
                      У тестовой локации ограниченный функционал: нет возможности отправлять пуш-уведомления.
                    </div>
                  </template>
                </TTAlert>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="6">
                <TTTextField
                  :value="entity.name"
                  large
                  label="Название"
                  placeholder="Введите название"
                  :error="validation('name').status"
                  :error-messages="validation('name').value"
                  data-test="input-name"
                  @input="v => handleChange('name',v)"
                />
              </VCol>
              <VCol cols="6">
                <TTAutocomplete
                  attach
                  label="Город"
                  no-rotate
                  large
                  :value="entity.city_id"
                  single-line
                  clear-icon="fas fa-times-circle"
                  append-icon="fal fa-search"
                  hide-details="auto"
                  placeholder="Введите город"
                  :items="cityList"
                  item-value="id"
                  item-text="name"
                  :search-input.sync="search"
                  :error="validation('city_id').status"
                  :error-messages="validation('city_id').value"
                  data-test="input-choose-city"
                  @input="v => handleChange('city_id',v)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <TTTextField
                  :value="entity.address"
                  large
                  label="Адрес"
                  placeholder="Введите адрес"
                  :error="validation('address').status"
                  :error-messages="validation('address').value"
                  data-test="input-adress"
                  @input="v => handleChange('address',v)"
                />
              </VCol>
              <VCol cols="6">
                <TTTextField
                  v-mask="'##'"
                  large
                  label="Зарплата, число месяца"
                  placeholder="Введите число"
                  data-test="input-payment-day"
                  :error="validation('payment_day').status"
                  :error-messages="validation('payment_day').value"
                  :value="entity.config.payment_day || ''"
                  @input="v => handleChangeConfig('payment_day',v)"
                />
              </VCol>
              <VCol cols="6">
                <TTTextField
                  v-mask="'##'"
                  large
                  label="Аванс, число месяца"
                  placeholder="Введите число"
                  data-test="input-prepayment-day"
                  :error="validation('prepayment_day').status"
                  :error-messages="validation('prepayment_day').value"
                  :value="entity.config.prepayment_day || ''"
                  @input="v => handleChangeConfig('prepayment_day',v)"
                />
              </VCol>
              <VCol cols="6">
                <TTSelect
                  v-model="entity.language"
                  :items="languages"
                  attach
                  data-test="select-language"
                  hide-details
                  label="Язык приложения"
                  large
                />
              </VCol>
              <VCol cols="12">
                <h3 class="tt-text-body-2 mb-3">
                  Приветственное сообщение, имя и иконка бота
                </h3>
                <VRow>
                  <VCol>
                    <VBtnToggle
                      v-model="botConfig"
                      mandatory
                    >
                      <TTBtn
                        large
                        color="tt-secondary"
                        class="tt-text-body-2 tt-light-mono-64--text"
                        :value="false"
                        data-test="location-form-bot-config-button-default"
                        @click="resetBotSettings"
                      >
                        Из основных настроек
                      </TTBtn>
                      <TTBtn
                        large
                        color="tt-secondary"
                        class="tt-text-body-2 tt-light-mono-64--text"
                        :value="true"
                        data-test="location-form-bot-config-button"
                      >
                        Индивидуальная настройка
                      </TTBtn>
                    </VBtnToggle>
                  </VCol>
                </VRow>
                <VRow v-if="botConfig">
                  <VCol cols="12">
                    <TTTextField
                      :value="entity.sms_text"
                      large
                      placeholder="Текст"
                      :messages="['Это сообщение получат новые сотрудники в SMS с приглашением в приложение.']"
                      :error="validation('sms_text').status"
                      :error-messages="validation('sms_text').value"
                      data-test="input-name"
                      data-test-label="location-form-smstext-input"
                      @input="v => handleChange('sms_text',v)"
                    >
                      <template #label>
                        <span data-test="location-form-smstext-label">Приветственное сообщение</span>
                      </template>
                      <template #message="{message}">
                        <span data-test="location-form-smstext-message">{{ message }}</span>
                      </template>
                    </TTTextField>
                  </VCol>
                  <VCol cols="6">
                    <TTTextField
                      :value="entity.bot_name"
                      large
                      label="Имя бота"
                      placeholder="Введите имя бота"
                      :error="validation('bot_name').status"
                      :error-messages="validation('bot_name').value"
                      data-test="config-bot-name-input"
                      @input="v => handleChange('bot_name',v)"
                    />
                  </VCol>
                  <VCol
                    cols="6"
                    data-test="location-form-icon-bot"
                  >
                    <h4 class="tt-text-body-2 mb-3">
                      Иконка бота
                    </h4>
                    <UploadAvatar
                      :value="entity.bot_icon_url || ''"
                      data-test-label-btn="location-form-icon-bot-btn"
                      mandatory
                      data-test="config-upload-avatar"
                      @input="v => handleChange('bot_icon_base64',v)"
                    />
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="12">
                <VCheckbox
                  color="tt-black"
                  :input-value="entity.is_auto_login"
                  data-test="checkbox"
                  data-test-label="location-form-is-auto-login"
                  @change="v => handleAutoLoginChange(v)"
                >
                  <template #label>
                    <span data-test="location-form-is-auto-login-label">Разрешить автовход по ссылке</span>
                  </template>
                </VCheckbox>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <div class="d-flex">
                  <TTBtn
                    v-if="entity.is_active || !entity.id"
                    large
                    color="tt-secondary"
                    depressed
                    class="mr-6"
                    data-test="button-cancel"
                    @click="() => handleClose()"
                  >
                    Отменить
                  </TTBtn>
                  <TTBtn
                    v-if="!entity.is_active && entity.id"
                    large
                    depressed
                    text
                    class="mr-6"
                    data-test-label="location-form-publish"
                    @click="openPublishDialog"
                  >
                    Опубликовать
                  </TTBtn>
                  <TTBtn
                    large
                    depressed
                    data-test="button-save"
                    @click="save(false)"
                  >
                    Сохранить
                  </TTBtn>
                </div>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              @click="() => handleClose()"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mask } from 'vue-the-mask';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import { handleError, validation, watchValidationStatus } from '@/services';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import UploadAvatar from '@/components/ui/UploadAvatar.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import ConfirmationForm from '@/components/forms/ConfirmationForm.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'LocationForm',
  components: {
    ConfirmationForm,
    DialogWrapper,
    DeleteForm,
    UploadAvatar,
  },
  directives: { mask },
  props: {
    editEntity: {
      type: Object,
      default: () => {
      },
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      botConfig: false,
      validationStatus: [],
      globalErrorMessage: '',
      autoLoginDialog: false,
      publishDialog: false,
      cityList: [],
      search: '',
      isLoading: false,
      entity: {
        name: '',
        address: '',
        city_id: 0,
        lat: 0,
        lng: 0,
        config: {
          points_enter: null,
          prepayment_day: null,
          payment_day: null,
        },
        is_auto_login: false,
        is_active: false,
        bot_name: '',
        bot_icon_base64: '',
        sms_text: '',
        language: 'ru',
      },
    };
  },
  computed: {
    ...mapGetters('customerInfo', ['languages']),
    hasAutoLogin() {
      return this.edit && !!this.editEntity.auto_login_url;
    },
    isShowAlert() {
      return !this.editEntity?.is_active && this.edit;
    },
  },
  watch: {
    search() {
      this.isLoading = true;
      this.handleSearch();
    },
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    this.handleSearch = debounce(this.draftSearch, 1000);
    const search = this.edit ? { id: this.editEntity.city.id } : { search: '' };
    this.$repositories.customer.getCity({
      data: {
        filter: search,
        page_size: 20,
      },
    })
      .then((r) => {
        const { data } = r.data;
        this.cityList = data;
      });
  },
  async mounted() {
    if (this.edit) {
      this.handleEntity();
    }
  },
  methods: {
    validation,
    handleError,
    async save(setIsActive) {
      try {
        const data = {
          ...this.entity,
          is_active: setIsActive ? true : this.entity.is_active,
        };
        if (this.edit) {
          await this.$repositories.location.update(data);
        } else {
          await this.$repositories.location.create(data);
        }
        this.handleClose('save');
      } catch (e) {
        this.handleError(e);
      }
    },
    openPublishDialog() {
      this.publishDialog = true;
    },
    handleEntity() {
      // cloneDeep нужен для того, что бы избежать мутации в дальнейших методах
      // Todo переписать на нормалное решение (сказывается на производительности)
      const {
        city,
        ...rest
      } = cloneDeep(this.editEntity);
      this.entity = {
        ...this.entity,
        ...rest,
        city_id: city.id,
        is_auto_login: this.hasAutoLogin,
      };
      if (this.entity.bot_icon_url || this.entity.bot_name || this.entity.sms_text) {
        this.botConfig = true;
      }
    },
    handleClose(e) {
      this.$emit(e || 'close');
    },
    handleChange(name, value, target) {
      this.entity[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    handleChangeConfig(name, value) {
      // WARNING в this.entity объект config хранится по ссылке
      this.entity.config[name] = value || null;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== name);
    },
    handleCancelDialog() {
      this.autoLoginDialog = false;
      this.handleAutoLoginChange(true);
    },
    handleAutoLoginChange(value) {
      this.entity.is_auto_login = value;
      if (this.hasAutoLogin && !value) {
        this.autoLoginDialog = true;
      }
    },
    draftSearch() {
      this.$repositories.customer.getCity({
        data: {
          filter: { search: this.search },
        },
      })
        .then((r) => {
          const { data } = r.data;
          this.cityList = data || [];
          this.isLoading = false;
        })
        .catch((e) => {
          console.warn(e);
          this.cityList = [];
          this.isLoading = false;
        });
    },
    resetBotSettings() {
      this.entity.bot_name = '';
      this.entity.bot_icon_base64 = '';
      this.entity.bot_icon_url = '';
      this.entity.sms_text = '';
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-label {
  font-size: 14px;
}

::v-deep .v-btn--active {
  color: #0B1218 !important;
}
</style>
